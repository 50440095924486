<template>
    <!-- Используйте выражение-тернарный оператор для динамического определения адреса для кнопки "Главная" -->
    <v-btn class="ml-0" min-width="0" text :to="isCustomer ? '/app/customerpage' : '/app/dashboard'" exact>
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>
  </template>
  
  <script>
  export default {
    name: "DefaultGoHome",
    computed: {
      // Проверка, что у пользователя роль "Customer"
      isCustomer() {
        const user = JSON.parse(localStorage.getItem("user"));
        return user && user.role === "Customer";
      }
    }
  };
  </script>